const Assets = {
  text: {
    companyName: "Tourism For Food",
  },
  images: {
    companyLogo: require("../../Assets/Images/Tourism-logo.png"),
    threeDots: require("../../Assets/Images/dots.png"),
    homeHeroImage: require("../../Assets/Images/home-hero-image.png"),
    homeEatChallenge: require("../../Assets/Images/eat-challenge.png"),
    homeDonateImage: require("../../Assets/Images/home-donate-image.png"),
    LiberianFlag: require("../../Assets/Images/liberian-flag.png"),
    travelBag: require("../../Assets/Images/Cartoon style traveling concept with baggage.png"),
    lightBuld: require("../../Assets/Images/Light bulb made with yellow ball of yarn on blue backdrop.png"),
    pushPin: require("../../Assets/Images/Hand-drawn green pushpin illustration.png"),
    aboutHistoryImage: require("../../Assets/Images/about__history.png"),
    ceoDummy: require("../../Assets/Images/CEO.png"),
    dooDummy: require("../../Assets/Images/DOO.png"),
    coomeEatImage: require("../../Assets/Images/come__eat__image.png"),
    coachMe: require("../../Assets/Images/coach__me.png"),
    bridgeBuilders: require("../../Assets/Images/bridge__builders.png"),
    volunteerCorp: require("../../Assets/Images/get-involved-volunteer-illustrator-removebg-preview.png"),
    ourPartnerships: require("../../Assets/Images/our__partnerships.png"),
    bookingsCardOne: require("../../Assets/Images/3d icon for traveling and vacation.png"),
    bookingsCardTwo: require("../../Assets/Images/Work team digital art.png"),
    mobileArt: require("../../Assets/Images/Mobile app location digital art.png"),
    featuredBlogImage: require("../../Assets/Images/featured__blog__img.png"),
    partnership: require("../../Assets/Images/partnership.png"),
    economicImpact: require("../../Assets/Images/economic__impact.png"),
    targetAudience: require("../../Assets/Images/target__audience.png"),
    globalHunger: require("../../Assets/Images/Global Hunger Issues.png"),
    volunteerism: require("../../Assets/Images/volunteerism.png"),
    // ONBOARDING SECTION
    onboardingOne: require("../../Assets/Images/Onboarding 1.png"),
    onboardingTwo: require("../../Assets/Images/Onboarding 2.png"),
    onboardingThree: require("../../Assets/Images/Onboarding 3.png"),
    loginIllustrator: require("../../Assets/Images/login-illustrator.png"),
    googleLogo: require("../../Assets/Images/google-icon.png"),
    facebookLogo: require("../../Assets/Images/facebook-icon.png"),
    appleLogo: require("../../Assets/Images/apple-icon.png"),
    verifyImage: require("../../Assets/Images/verify.png"),
  },
};

export { Assets };
